<template>
    <div>
        <header-portrate />

    <div class="pa-2 m-2">
        
        <bread-crumbs :items="items"></bread-crumbs>
        
        <tabs-comp :tabs="tabs" />
        <v-row style="text-align:center;direction:rtl" class="m-2">
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <label>اختر الشهر</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="month"
                            :options="months"
                            label="اختر الشهر"
                            style="width:200px;"
                            @change="getEmployees()"
                        ></b-form-select>
                            <b-input-group-addon style="margin-right:-30px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="2">
                        <label>السنة</label>
                        <b-form-input class="inborder"
                        label="السنة"
                        v-model="year"
                        type="number"
                        style="width:200px;"
                        @change="getEmployees()"
                        >{{ year }}</b-form-input>
                    </v-col>
                    <v-col cols="8" :style="`text-align:`+lang.lalgin">
                        <b-button class="btn-sm btn-success pa-1 m-2" @click="exportE('e')" style="min-width:150px;">تصدير اكسل</b-button>
                        <b-button class="btn-sm btn-danger pa-1 m-2" style="display:none;min-width:150px;">طباعة PDF</b-button>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <b-table-simple>
                    <thead>
                        <tr>
                            <th>اسم الموظف</th>
                            <th>اجمالي الراتب</th>
                            <th>الخصومات</th>
                            <th>السلف</th>
                            <th>المكافآت</th>
                            <th>نسبة كروت الصيانة</th>
                            <th>نسبة المبيعات</th>
                            <th>صافي الراتب</th>
                            <th>ملاحظات</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in rsalary" :key="index">
                            <td>{{item.emp_name}}</td>
                            <td>{{item.stotal}}</td>
                            <td>{{item.discounts}}</td>
                            <td>{{item.padvanced}}</td>
                            <td>{{item.bonuce}}</td>
                            <td>{{item.ptotal}}</td>
                            <td>{{item.ipercentage}}</td>
                            <td>{{item.paidsalary}}</td>
                            <td>{{item.notes}}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-col>
        </v-row>
    </div>

        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: { breadCrumbs, TabsComp, HeaderPortrate, Footer },
    name: 'salary-table',
    data() {
        return {
            employees:[],
            cardsrows: [],
            invoicerows: [],
            rewarded: [],
            payadvanced: [],
            deducts: [],
            custodies: [],
            salaryregisterd: [],
            endofservice: 0,
            months: [
                {text: "January",value:1},
                {text: "February",value:2},
                {text: "March",value:3},
                {text: "April",value:4},
                {text: "May",value:5},
                {text: "June",value:6},
                {text: "July",value:7},
                {text: "August",value:8},
                {text: "September",value:9},
                {text: "October",value:10},
                {text: "November",value:11},
                {text: "December",value:12},
            ],
            month: '',
            year: 2022,
            rsalary: [],
            ppsalary: [],
        }
    },
    created() {
        this.getMonth();
        this.getEmployees();
    },
    methods: {
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        exportE(type){
            const post = new FormData();
            post.append("export","salary");
            post.append("type",type);
            post.append('month',this.month);
            post.append('year',this.year);
            axios.post(
                this.$store.state.SAMCOTEC.e_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                window.open('../api/'+res.url,'_blank');
            })
        },
        getMonth(){
            let tm = new Date();
            this.month = parseFloat(tm.getMonth()) + parseFloat(1);
            this.year = tm.getFullYear()
        },
        getEmployees() {
            const post = new FormData();
            post.append("type" , "getAllEmpTable");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.rsalary = [];
                const res = response.data.results.data;
                for(let i=0;i<res.length;i++){
                    
                    this.rsalary.push(res[i]);
                    
                }
                // // console.log("asdfasdf",res);
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
            return {
                    text: 'مسير الرواتب',
                    disabled: true,
                    to: '/emp/view',
                }
        },
        tabs: function(){
            return [
                {
                    index:1,name:this.lang.employees,href:'/emps/salaries',class: 'mytab1'
                },
                {
                    index:1,name:this.lang.salary_table,href:'/emps/salary-table',class: 'mytab'
                },
                {
                    index:1,name:this.lang.gozi_report,href:'/emps/gozi-report',class: 'mytab1'
                },
                // {
                //     index:1,name:this.lang.users,href:'/emps/users-settings',class: 'mytab2'
                // }
            ]
        },
    }
}
</script>
